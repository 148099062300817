import React, { useState } from 'react';

// Material UI
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, CircularProgress, InputAdornment, FormControlLabel, Switch, Paper, Button } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Card, CardContent, CardHeader } from '@material-ui/core';

// Components
import HostPricingDetails from './HostPricingDetails';
import ConfirmAction from 'components/popups/ConfirmAction';
import { TextField } from 'components/ui/inputs';

// Utility
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import useExpresso from 'hooks/useExpresso';
import { validateURL } from 'utility/validation';
import { useNumberInput, useTextInput, useToggle } from 'hooks/forms';


export default function HostFormContainer(props) {
    if (props.hostId) {
        return <HostEditForm {...props} editMode={true} />
    }
    else {
        return <HostCreateForm {...props} editMode={false} />
    }
}


function HostCreateForm(props) {
    const { onUpdate, onCancel } = props;

    const [loading, setLoading] = useState(false);
    const { createNotification } = useNotification();

    const handleSubmit = (form) => {
        setLoading(true);

        fetchExpresso(`/apiv2/hosts/`, { method: 'POST', body: form })
            .then(res => {
                if (res.status === 200) {
                    createNotification('Created new host');
                    onUpdate();
                } else {
                    throw new Error();
                }
            })
            .catch(() => createNotification('There was a problem creating your host'))
            .finally(() => setLoading(false))
    };

    return (
        <HostForm
            editMode={false}
            loading={loading}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        />
    )
}

function HostEditForm(props) {
    const { onUpdate, onCancel, hostId } = props;

    const [host, loadingHost, error]  = useExpresso(`/apiv2/hosts/${hostId}`, 'GET', null, null, []);

    const [loading, setLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteResults, setDeleteResults] = useState(null);

    const { createNotification } = useNotification();


    const handleSubmit = async (form) => {
        setLoading(true);

        fetchExpresso(`/apiv2/hosts/${hostId}`, { method: 'PUT', body: form })
            .then(res => {
                if (res.status === 200) {
                    createNotification('Host updated');
                    onUpdate();
                } else {
                    throw new Error();
                }
            })
            .catch(() => createNotification('There was a problem updating your host'))
            .finally(() => setLoading(false))
    };

    const handleDelete = () => {
        setConfirmDelete(false);
        setLoading(true);

        // Delet Host
        fetchExpresso(`/apiv2/hosts/${props.hostId}`, { method: 'DELETE' })
            .then(res => {
                if (res.status !== 200) throw new Error();
                return res.json();
            })
            .then(data => setDeleteResults(Object.entries(data)))
            .catch(() => createNotification('There was a problem deleting your host'))
            .finally(() => setLoading(false))
    }

    if (loadingHost) {
        return (
            <Box display='flex' flex={1} flexDirection='column' justifyContent='center' alignItems='center'>
                <CircularProgress />
            </Box>
        )
    }
    
    if (error) {
        return (
            <Box display='flex' flex={1} flexDirection='column' justifyContent='center' alignItems='center'>
                <p>Error loading host</p>
            </Box>
        )
    }

    if (host) {
        const { url_website, url_facebook, url_twitter, url_instagram, url_tiktok, connect_id } = host;

        return (
            <>
                <HostForm
                    hostId={hostId}
                    loading={loading}
                    editMode={true}
                    onSubmit={handleSubmit}
                    onDelete={handleDelete}
                    onCancel={onCancel}
                    name={host.host_name}
                    description={host.host_desc}
                    phone={host.contact_phone}
                    email={host.contact_email}
                    subDomain={host.sub_domain}
                    ccStatementDescriptor={host.cc_statement_descriptor}
                    website={url_website}
                    facebook={url_facebook}
                    twitter={url_twitter}
                    instagram={url_instagram}
                    tiktok={url_tiktok}
                    connectEnabled={Boolean(connect_id)}
                />
                <ConfirmAction
                    open={confirmDelete}
                    destructive
                    onCancel={() => setConfirmDelete(false)}
                    onConfirm={handleDelete}
                    confirmText='Delete'
                    title='Delete this host?'
                    description='This will delete all data associated with this host, including events, tickets, and purchase records'
                />

                {deleteResults !== null && (
                    <Dialog open onClose={onUpdate}>
                        <DialogTitle>Results</DialogTitle>
                        <DialogContent>
                            <Table>
                                <TableBody>
                                    {deleteResults.map(p => (
                                        <TableRow key={p[0]}>
                                            <TableCell>{p[0]}</TableCell>
                                            <TableCell>{p[1]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </DialogContent>
                        <DialogActions>
                            <Button color='secondary' onClick={onUpdate}>OK</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </>
        )
    }
}

function HostForm(props) {
    // Props
    const { onSubmit, onCancel, onDelete, hostId, editMode, loading } = props;

    // State - Form Values
    const name          = useTextInput({ defaultValue: props.name || '' });
    const description   = useTextInput({ defaultValue: props.description || '' });
    const phone         = useNumberInput({ defaultValue: props.phone || '' });
    const email         = useTextInput({ defaultValue: props.email || '' });
    const subDomain     = useTextInput({ defaultValue: props.subDomain || '' });
    const ccDescriptor  = useTextInput({ defaultValue: props.ccStatementDescriptor || '', maxLength: 18 });
    const website       = useTextInput({ defaultValue: props.website || '' });
    const facebook      = useTextInput({ defaultValue: props.facebook || '' });
    const twitter       = useTextInput({ defaultValue: props.twitter || '' });
    const instagram     = useTextInput({ defaultValue: props.instagram || '' });
    const tiktok        = useTextInput({ defaultValue: props.tiktok || '' });

    // Creates a stripe connect account for host by default
    // Don't allow Stripe Connect to be turned off if it is already enabled
    const connectEnabled = useToggle({ defaultValue: props.connectEnabled ?? false, disabled: editMode && props.connectEnabled === true })


    const theme = useTheme();


    function handleSubmit() {
        if (loading) return null;

        // ***** Error Handling ***** //

        // All values except name can be blank
        // Only validate if a value is provided

        let formHasError = false;

        if (name.value.trim() === '') {
            name.setError('This field cannot be empty');
            formHasError = true;
        }

        if (email.value.trim() !== '' && /.+@.+\..+/.test(email.value.trim()) === false) {
            email.setError('Make sure the email is formatted properly')
            formHasError = true;
        }

        if (validateURL(website.value) === false && website.value.trim() !== '') {
            website.setError('Make sure your link is properly formatted. Example: https://example.com')
            formHasError = true;
        }

        if (validateURL(facebook.value) === false && facebook.value.trim() !== '') {
            facebook.setError('Make sure your link is properly formatted. Example: https://facebook.com/example')
            formHasError = true;
        }

        if (validateURL(twitter.value) === false && twitter.value.trim() !== '') {
            twitter.setError('Make sure your link is properly formatted. Example: https://twitter.com/example')
            formHasError = true;
        }

        if (validateURL(instagram.value) === false && instagram.value.trim() !== '') {
            instagram.setError('Make sure your link is properly formatted. Example: https://instagram.com/example')
            formHasError = true;
        }

        if (validateURL(tiktok.value) === false && tiktok.value.trim() !== '') {
            tiktok.setError('Make sure your link is properly formatted. Example: https://www.tiktok.com/@example')
            formHasError = true;
        }


        if (formHasError) {
            return null;
        }

        onSubmit({
            name: name.value,
            description: description.value,
            phone: phone.value,
            email: email.value,
            subDomain: subDomain.value,
            ccStatementDescriptor: ccDescriptor.value,
            website: website.value.trim() || '',
            facebook: facebook.value.trim() || '',
            twitter: twitter.value.trim() || '',
            instagram: instagram.value.trim() || '',
            tiktok: tiktok.value.trim() || '',
            connectEnabled: connectEnabled.checked
        });
    }
    

    return (
        <Box width={1} display='flex' flexDirection='column' alignItems='center'>
            <Box width={[0.9, 0.9, theme.breakpoints.values.md - 60]} my={6} display='flex' justifyContent={editMode ? 'space-between' : 'center'} flexWrap='wrap'>

                <Box width={[1, 1, 0.6]} display='flex' flexDirection='column'>

                    <FormContainer>

                    <TextField required
                        {...name.formProps}
                        label='Host Name'
                        margin='normal'
                    />

                    <TextField
                        {...description.formProps}
                        label='Description'
                        margin='normal'
                    />

                    <TextField
                        {...phone.formProps}
                        label='Phone'
                        margin='normal'
                    />

                    <TextField
                        {...email.formProps}
                        label='Email'
                        margin='normal'
                    />

                    <TextField
                        {...subDomain.formProps}
                        label='Sub Domain'
                        InputProps={{ endAdornment: <InputAdornment position="end">.frontdoor.plus</InputAdornment> }}
                        margin='normal'
                    />

                    <TextField
                        {...ccDescriptor.formProps}
                        InputProps={{ startAdornment: <InputAdornment position="start">FD*_</InputAdornment> }}
                        label='CC Statement Descriptor'
                        margin='normal'
                    />

                    <TextField
                        {...website.formProps}
                        type='url'
                        label='Website URL'
                        placeholder='https://example.com'
                        margin='normal'
                    />

                    <TextField
                        {...facebook.formProps}
                        type='url'
                        label='Facebook URL'
                        placeholder='https://facebook.com/example'
                        margin='normal'
                    />

                    <TextField
                        {...twitter.formProps}
                        type='url'
                        label='Twitter URL'
                        placeholder='https://twitter.com/example'
                        margin='normal'
                    />

                    <TextField
                        {...instagram.formProps}
                        type='url'
                        label='Instagram URL'
                        placeholder='https://instagram.com/example'
                        margin='normal'
                    />

                    <TextField
                        {...tiktok.formProps}
                        type='url'
                        label='Tiktok URL'
                        placeholder='https://www.tiktok.com/@example'
                        margin='normal'
                    />

                    <Box mt={2} mb={4}>
                        <FormControlLabel
                            control={<Switch {...connectEnabled.formProps} />}
                            label="Enable Stripe Connect"
                        />
                    </Box>

                    <Box display='flex' justifyContent='flex-end' mb={4}>
                        {(editMode && process.env.REACT_APP_ENV === 'development') && (
                            <DeleteButton variant='outlined' onClick={onDelete} disabled={loading}>Delete</DeleteButton>
                        )}
                        <CancelButton variant='outlined' onClick={onCancel} disabled={loading}>Cancel</CancelButton>
                        <SaveButton variant='outlined' onClick={handleSubmit} disabled={loading}>Save</SaveButton>
                    </Box>

                    </FormContainer>

                </Box>

                {editMode && (
                    <Box width={[1, 1, 0.3]} mt={1}>
                        <Card>
                            <CardHeader title='Fee Breakdown' />
                            <CardContent>
                                <HostPricingDetails hostId={hostId} />
                            </CardContent>
                        </Card>
                    </Box>
                )}

            </Box>
        </Box>
    )
}

const FormContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
}));

const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
}));

const CancelButton = styled(Button)({
    marginRight: '1rem'
});

const DeleteButton = styled(Button)(({ theme }) => ({
    marginRight: 'auto',
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
}));